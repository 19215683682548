<template>
  <div class="p-4" v-if="loaded">
    <div v-if="registrations && registrations.result" class="mt-1 rounded-md">
      <form @submit.prevent="importRegistrations">
        <table class="w-full">
          <tr class="group odd:bg-gray-100 first:border-t border-b border-gray-200 flex" v-for="(registration, key) in registrations.result" :key="key">
            <td>
              <label class="pr-2" :for="registration.date">
                <input :id="registration.date" type="checkbox" v-model="checkedRegistrations" :value="key" />
                {{ formatDate(registration.date) }}
              </label>
            </td>
            <td class="flex">
              <span class="flex" v-for="(pmmodule, key) in registrationPmModules(registration)" :key="key">
                <p class="pr-2" v-if="key != 0">,</p>
                <p @click="openPreview(pmmodule, registration[pmmodule], registration.date)">
                  {{ name(pmmodule) }}
                </p>
              </span>
            </td>
          </tr>
          <tr class="group odd:bg-gray-100 first:border-t border-b border-gray-200">
            <td colspan="2">
                <cm-button
                  type="submit"
                  :disabled="checkedRegistrations.length == 0"
                >
                  {{ $t("button.import") }}
                </cm-button>
                <cm-button
                  type="button"
                  visual="danger"
                  :disabled="checkedRegistrations.length == 0"
                  @click.prevent="excludeRegistrations"
                >
                  {{ $t("button.exclude") }}
                </cm-button>
            </td>
          </tr>
        </table>
      </form>
    </div>
    <div v-else>
      <p>{{ $t("pm.no_registrations") }}</p>
    </div>

    <modal
      name="module-preview"
      :clickToClose="true"
      height="500px"
      classes="bg-white rounded-lg shadow flex flex-col"
    >
      <module-preview :structure="structure" :info="info" :data="moduleData" />
    </modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { dateFormat } from "@/utils/date";
import ModulePreview from "@/components/patient/pm/ModulePreview";
export default {
  name: "PatientPmRegistrations",
  components: {
    ModulePreview,
  },
  data() {
    return {
      loaded: false,
      checkedRegistrations: [],
      translation: [],
      moduleData: null,
    };
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      await this.$store.dispatch("patient/pmRegistrations");
      await this.moduleTranslations();
      this.loaded = true;
    },
    async importRegistrations() {
      for (var i = 0; i < this.checkedRegistrations.length; i++) {
        //Loop through choosen registrations
        for (var key in this.registrations.result[this.checkedRegistrations[i]]) {
          //Loop through every module in registration
          if (!["date", "meta"].includes(key)) {
            await this.$store.dispatch("meta/loadFormStructure", key); //Load formstructure to remove unused vars

            var usedVars = this.structure.structure.elements.map(v => v.name);

            for (var variable in this.registrations.result[this.checkedRegistrations[i]][key]) {
              if (variable.startsWith(key + "_") && usedVars.includes(variable.replace(key + "_", ""))) {
                this.registrations.result[this.checkedRegistrations[i]][key][variable.replace(key + "_", "")] = this.registrations.result[this.checkedRegistrations[i]][key][variable];
              }

              if (!usedVars.includes(variable)) {
                delete this.registrations.result[this.checkedRegistrations[i]][key][variable];
              }
            }

            this.registrations.result[this.checkedRegistrations[i]][key][
              "pm_registration_id"
            ] = this.checkedRegistrations[i]; //Set pm registration id

            this.registrations.result[this.checkedRegistrations[i]][key][
             "date"
            ] = this.registrations.result[this.checkedRegistrations[i]]["date"]; //Set date to pm registration date

            this.$store.dispatch("patient/submitPost", {
              id: null,
              formname: key,
              data: this.registrations.result[this.checkedRegistrations[i]][key],
            });
          }
        }

        this.$store.dispatch("patient/pmImport", {
          external_id: parseInt(this.checkedRegistrations[i]),
          data: this.registrations.result[this.checkedRegistrations[i]],
        });
      }

      this.$store.dispatch("patient/pmRegistrations");
      this.$router.push({ name: "patient-basedata" });
    },
    async excludeRegistrations() {
      let buttons = [
        {
          title: this.$t("button.cancel"),
          handler: () => {
            this.$modal.hide("dialog");
          },
        },
        {
          title: this.$t("button.continue"),
          class: "vue-dialog-button bg-red-500 text-white hover:bg-red-600",
          handler: () => {
            this.$modal.hide("dialog");
            for (var registration in this.checkedRegistrations) {
              this.$store.dispatch("patient/pmExclude", {
                external_id: parseInt(this.checkedRegistrations[registration])
              });
            }

            this.$store.dispatch("patient/pmRegistrations");
            this.$router.push({ name: "patient-basedata" });
          },
        },
      ];
      this.$modal.show("dialog", {
        title: this.$t("patient.dialog.pm.title"),
        text: this.$tc("patient.dialog.pm.text", this.checkedRegistrations.length),
        buttons: buttons,
      });
    },
    async moduleTranslations() {
      this.translation = [];
      if (this.registrations.meta) {
        for (let key in this.registrations.meta) {
          this.translation[
           this.registrations.meta[key].name
          ] = this.registrations.meta[key].translation.label;
        }
      }
    },
    name(module) {
      return this.translation[module] ? this.translation[module] : module;
    },
    async openPreview(pmmodule, data, date) {
      await this.$store.dispatch("meta/loadFormStructure", pmmodule);

      if (this.structure && this.structure.name == pmmodule) {
        for (var key in data) { //Dont think current pm will work so well if changing variable names to not prefix with module name so use this to start with
          if (key.startsWith(pmmodule + "_")) {
            data[key.replace(pmmodule + "_", "")] = data[key];
          }
        }

        data['date'] = dateFormat(date); //Set registration date

        this.moduleData = data;
        this.$modal.show("module-preview");
      } else {
        this.$toasted.error(this.$t('pm.preview_error', {
          'module': this.name(pmmodule)
        }), { duration : 4000 });
      }
    },
    registrationPmModules(registration) {
      return Object.keys(registration).filter(d => !["date", "meta"].includes(d));
    },
    formatDate(date) {
     return date ? dateFormat(date, 'Y-MM-dd HH:MM') : null;
    },
  },
  computed: {
    ...mapState("patient", {
      registrations: state => state.pmRegistrations,
      info: state => state.info
    }),
    ...mapState("meta", {
      structure: state => state.formstructure,
    }),
  },
};
</script>