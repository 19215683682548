<template>
  <div class="rounded-lg flex flex-col">
    <module-form-header :label="label" :patient="info" :data="{}" />
    <div class="overflow-y-auto p-4 px-4 shadow pb-10 module-preview" style="height:420px;">
      <div v-for="(element, key) in variables" :key="key" class="flex-1 mt-1">
        <label class="block text-sm font-normal text-gray-900">
          <span v-if="element.translation">{{ element.translation.label }}</span>
        </label>
        <div class="relative mb-1">
          <cm-input-text
            v-if="getElementType(element) === 'string'"
            v-model="data[element.name]"
            :name="element.name"
            :disabled="true"
          />

          <cm-input-number
            v-if="getElementType(element) === 'number'"
            v-model="data[element.name]"
            :name="element.name"
            :disabled="true"
          />

          <cm-datepicker-2
            v-if="getElementType(element) === 'date'"
            v-model="data[element.name]"
            type="date"
            :name="element.name"
            :disabled="true"
          />

          <cm-slider
            v-if="getElementType(element) === 'slider'"
            v-model="data[element.name]"
            :name="element.name"
            :min="element.min"
            :max="element.max"
            :disabled="true"
          />

          <cm-textarea
            v-if="getElementType(element) === 'mediumtext'"
            v-model="data[element.name]"
            :name="element.name"
            :disabled="true"
          />

          <cm-select
            v-if="getElementType(element) === 'select'"
            v-model="data[element.name]"
            :name="element.name"
            :options="element.options"
            :disabled="true"
          />
        </div>
      </div>
    </div>
    <button @click="$modal.hide('module-preview')" class="pt-2 ">{{ $t("button.close") }}</button>
  </div>
</template>

<script>
import ModuleFormHeader from "@/components/patient/module/ModuleFormHeader";

export default {
  name: "modulePreview",
  props: ["structure", "info", "data"],
  components: {
    ModuleFormHeader
  },
  computed: {
    variables() {
      if (!this.structure) return;
      return this.structure?.structure?.elements;
    },
    layout() {
      return this.structure?.structure;
    },
    variableLayout() {
      if (!this.variables) return;

      if (!this.layout) {
        return [{ flex: "col", vars: this.variables }];
      }

      let layout = this.layout.reduce((acc, cur) => {
        let vars = cur.names.map(d => {
          return this.variables.find(v => v.name === d);
        });
        acc.push({
          flex: cur?.flex || "row",
          header: cur?.header || null,
          vars: vars,
        });
        return acc;
      }, []);

      return layout;
    },
    label() {
      return  `${this.structure.translation.label} - ` + this.$t("pm.preview");
    },
  },
  methods: {
    getElementType(el) {
      if (!el.element) {
        return false;
      }

      let element = el.element.toLowerCase();

      let elementType = el.type.toLowerCase();
      // if (el.subtype !== null) {
      //   elementType += ["_", el.subtype.toLowerCase()].join();
      // }

      if (element === "input") {
        if (el.type) {
          return el.type;
        }

        switch (elementType) {
          case "integer":
          case "double":
            return "number";
          case "slider":
            return "slider";
          default:
            return "string";
        }
      }

      return element;
    },
  },
};
</script>

<style>
.module-preview {
  overflow: auto;
  height: 448px;
}
</style>